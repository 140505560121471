import React from 'react'
import {graphql} from 'gatsby'

import ArchivesPage from '../templates/archives'

export default function Index({data: {allMdx}}) {
  return <ArchivesPage title="分类列表" allMdx={allMdx} keyName="categories" />
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {
        frontmatter: {published: {ne: false}, unlisted: {ne: true}}
        fileAbsolutePath: {regex: "//content/blog//"}
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
            datetime
            description
            categories
            keywords
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            keywords
            categories
          }
        }
      }
    }
  }
`
